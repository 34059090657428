<template>
  <div class="page">
    <Head :headTitle="headTitle" />
    <div class="container">
      <div class="con-l">
        <div class="mod mod1">
          <div class="s-pub-tt flex">
            <span class="bt">乡贤人数</span>
          </div>
          <ul class="mod1-list flex-1">
            <li v-for="item in xxList" :key="item.index">
              <div class="tit">{{ item.xxTitle }}</div>
              <div class="txt">
                <span>{{ item.xxNum }}</span
                >人
              </div>
            </li>
            <div style="width: 45%; height: 1px"></div>
          </ul>
        </div>
      </div>
      <div class="con-c flex flex-v relative">
        <div class="cen-map">
          <div class="mod2-map">
            <div class="map_box">
              <div class="item_education item item1">
                <div class="txt">
                  <p>
                    <span class="tit">乡贤总数</span>
                    <em> 1200</em>
                    <span class="tit tit1">人</span>
                  </p>
                </div>
              </div>
              <div class="item_hosptial item item2">
                <div class="txt">
                  <p>
                    <span class="tit">捐款金额</span>
                    <em>5.6</em>
                    <span class="tit">亿</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cen-bot">
          <div class="item item1">
            <div class="top_top">
              <img class="top_img" src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/fujian/fuan/photo/xx_1.png" alt="">
              <div class="flex flex-align-center">
                  <h6 class="name">兴华财富集团公司</h6>
                </div>
            </div>
            <div class="top">
              <div class="info">
                <div class="tel">
                  <p>捐款金额:<em style="color: red;font-size: 34px; ">35</em>万元</p>
                </div>
              </div>
            </div>
            <div class="txt">
              <em style="color: #fff">城阳茂春助学基金还为城阳镇的108位大学生、研究生和贫困大学生发放每人2000元至10000元的奖助学金，同时还向老区中学、城阳中心小学、茶洋小学、实验幼儿园集团城阳园等学校捐赠款项，总计金额35万多元。</em>
            </div>
          </div>
          <div class="item item2">
            <div class="top_top">
              <img class="top_img" src="https://video-1316200666.cos.ap-guangzhou.myqcloud.com/fujian/fuan/photo/xx_2.png" alt="">
              <div class="flex flex-align-center">
                  <h6 class="name">穆阳镇</h6>
                </div>
            </div>
            <div class="top">
              <div class="info">
                <div class="tel">
                  <p>捐款金额:<em style="color: red;font-size: 34px; ">50</em>万元</p>
                </div>
              </div>
            </div>
            <div class="txt">
              <em style="color: #fff">通过主动与外出党员人才沟通联系，展示家乡发展变化，邀请在外乡贤回乡创业投资，交流学习先进经验，用“实心化”产业打破传统“老路子”。春节期间，仅穆阳溪游船、热气球漫步云端项目运营直接盈利6万元多元，接待游客达10万多人次，带动周边群众增收50多万元。</em>
            </div>
          </div>
        </div>
      </div>
      <div class="con-r flex flex-v">
        <div class="mod mod7">
          <div class="s-pub-tt flex">
            <span class="bt">风采展示</span>
          </div>
          <ul class="mod7-list flex-1">
            <li class="item" v-for="item in imgList" :key="item.id">
              <img :src="item.url" class="img img_full" alt="" />
            </li>
          </ul>
        </div>
        <div class="mod mod6">
          <div class="s-pub-tt flex">
            <span class="bt">乡贤贡献列表</span>
          </div>
          <div class="s-scroll-list flex-1 flex flex-v">
            <div class="row row-t">
              <div style="width: 60%; text-align: center">
                {{ xxAbout.head1 }}
              </div>
              <div style="width: 40%; text-align: center">
                {{ xxAbout.head2 }}
              </div>
            </div>
            <vue-seamless-scroll
              :data="xxAboutList"
              :class-option="scrollOption"
              class="flex-1"
            >
              <div class="scrollbox">
                <div
                  class="row row-b"
                  v-for="(item, index) in xxAboutList"
                  :key="index"
                >
                  <div style="width: 60%; text-align: center">
                    {{ item.title }}
                  </div>
                  <div style="width: 40%; text-align: center">
                    {{ item.xCountry }}
                  </div>
                </div>
              </div>
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Head from "@/components/head_sm.vue";
import axios from "axios";
export default {
  data() {
    return {
      headTitle: "",
      xxList: [],
      xxAbout: {},
      xxAboutList: [],
      indexNum: 0, //随机数,
      // tipsList:[],
      tipsList: [
        {
          index: 0,
          name: "楼下村",
          num: 100,
        },
        {
          index: 1,
          name: "英花村",
          num: 500,
        },
        {
          index: 2,
          name: "硕和村",
          num: 600,
        },
        {
          index: 3,
          name: "硕榕村",
          num: 400,
        },
        {
          index: 4,
          name: "硕联村",
          num: 300,
        },
        {
          index: 5,
          name: "五房村",
          num: 700,
        },
        {
          index: 6,
          name: "北良村",
          num: 500,
        },
        {
          index: 7,
          name: "仙美村",
          num: 600,
        },
        {
          index: 8,
          name: "下坝村",
          num: 400,
        },
        {
          index: 9,
          name: "溢溪村",
          num: 700,
        },
        {
          index: 10,
          name: "秋江村",
          num: 800,
        },
        {
          index: 11,
          name: "坪埔村",
          num: 600,
        },
        {
          index: 12,
          name: "白石村",
          num: 200,
        },
      ], //冒泡数组
      xxAboutList: [],
      imgList: [
        {
          id: "0",
          url: "https://video-1316200666.cos.ap-guangzhou.myqcloud.com/fujian/fuan/photo/xx_1.png",
        },
        {
          id: "1",
          url: "https://video-1316200666.cos.ap-guangzhou.myqcloud.com/fujian/fuan/photo/xx_2.png",
        },
        {
          id: "2",
          url: "https://video-1316200666.cos.ap-guangzhou.myqcloud.com/fujian/fuan/photo/xx_3.png",
        },
        {
          id: "3",
          url: "https://video-1316200666.cos.ap-guangzhou.myqcloud.com/fujian/fuan/photo/xx_4.png",
        },
        {
          id: "4",
          url: "https://video-1316200666.cos.ap-guangzhou.myqcloud.com/fujian/fuan/photo/xx_5.png",
        },
        {
          id: "5",
          url: "https://video-1316200666.cos.ap-guangzhou.myqcloud.com/fujian/fuan/photo/xx_6.png",
        },
      ],
    };
  },
  components: {
    Head,
  },
  computed: {
    scrollOption() {
      return {
        step: 0.3, // 数值越大速度滚动越快
        // limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  created() {
    this.getData();
    this.getxxList();
    this.getxxAbout();
  },
  mounted() {
    setInterval(() => {
      this.indexNum = Math.floor(Math.random() * (this.tipsList.length - 1));
    }, 3000);
  },
  beforeDestroy() {
    clearInterval();
  },
  methods: {
    getData() {
      axios
        .get("/10ksxxwh.json", {
          params: {
            random: Math.random(),
          },
        })
        .then((res) => {
          // 处理响应
          let dataObject = res.data; // 这里假设获取到的数据是一个 Object 类型的数据
          // console.log(dataObject.data);
          //
          let jsonString = JSON.stringify(dataObject); // 将 Object 类型的数据转换为字符串类型的 JSON 数据
          // console.log(jsonString) // 输出字符串类型的 JSON 数据
          let jsonObject = JSON.parse(jsonString);
          this.webData = jsonObject;
          //标题
        })
        .catch((error) => {
          console.log(error.response.status);
          console.log(error.response.data);
          console.log(error.response.headers);
        });
    },
    async getxxList() {
      const res = await axios.get("/10ksxxwh.json");
      let dataObject = res.data;
      console.log("res.data====>", res.data);
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.headTitle = jsonObject.bigTitle;
      this.xxList = jsonObject.xxList;
      console.log("xxList===>", this.xxList);
    },
    async getxxAbout() {
      const res = await axios.get("/10ksxxwh.json");
      let dataObject = res.data;
      console.log("res.data====>", res.data);
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.xxAbout = jsonObject.xxAbout;
      this.xxAboutList = jsonObject.xxAbout.xxList;
      console.log("this.xxAboutList===>", this.xxAboutList);
    },
  },
};
</script>

<style scoped>
.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background: url(https://video-1316200666.cos.ap-guangzhou.myqcloud.com/kashi/background2.png)
    no-repeat center;
  background-size: cover;
  background-color: #0a224b;
}

.mod {
  margin-top: 10%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}
.mod:last-child {
  bottom: 0;
}
.mod1 {
  height: calc(90% - 10px);
}
.mod6 {
  margin-top: 5%;
  height: calc(50.5% - 20px);
}
.mod1-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.mod1-list li {
  margin-top: 2%;
  width: 45%;
  height: 7%;
  background-color: #08172e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  font-size: 32px;
  color: #fff;
}
.mod1-list .tit {
  font-size: inherit;
}
.mod1-list .txt {
  font-size: inherit;
}
.mod1-list .txt span {
  font-size: 40px;
  color: #45f5ff;
  font-family: "simpds";
  padding-right: 8px;
}
.mod1-list .txt i {
  font-family: "simpds";
}
.mod7 {
  height: calc(50.5% - 20px);
}
.mod7-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.mod7-list .item {
  width: 30%;
  position: relative;
  overflow: hidden;
}
.mod7-list .title {
  font-size: 30px;
  color: #fff;
  text-align: center;
  line-height: 34px;
  padding: 15px 10px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #0a1f3e;
}

.mod2-map {
  position: absolute;
  width: calc(100% + 300px);
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  margin-left: -150px;
}

.mod2-map .item,
.mod2-map .item3,
.mod2-map .item7 {
  /* width: 500px; */
  height: 100px;
  padding: 20px;
  border-radius: 10px;
  /* position: absolute; */
}
.item_education em,
.item_hosptial em {
  padding: 0px 30px;
  font-size: 50px;
  color: #45f5ff;
}
.map_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 3%;
}
.map_box .item {
  width: 700px;
}

.item_education {
  /* text-indent: 100px; */
  background: url(../assets/images/icon_08.png) no-repeat center;
  background-size: 100% 100%;
}
.item_hosptial {
  text-indent: 25px;
  background: url(../assets/images/icon_09.png) no-repeat center;
  background-size: 100% 100%;
}
.mod2-map .item .txt p,
.mod2-map .item3 .txt p,
.mod2-map .item7 .txt p {
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  /* margin-top: 5px; */
  padding-left: 200px;
}
.mod2-map .item .tit,
.mod2-map .item3 .tit,
.mod2-map .item7 .tit {
  color: #fff;
  font-size: 35px;
}
.mod2-map .item .txt * {
  font-family: "YouSheBiaoTiHei";
}
.con-c{
  position: relative;
}
.cen-bot {
  position: absolute;
  bottom:15%;
  width: 100%;
  height: 1000px;
}
.cen-bot .item{
  position: absolute;
  width: 800px;
  /* height: 300px; */
  border-radius: 12px 12px 12px 12px;
  background-color: rgba(14,28,48,.5);
  z-index: 50;
  padding: 30px;
}
.cen-bot .item1{
  bottom:15%;
  left:15%;
}
.cen-bot .item2{
  top:0%;
  left:52%;
}
.cen-bot .item .top_top{
  display: flex;
  vertical-align: baseline;
  width: 100%;
  border-radius: 50%;
}
/* .cen-bot .item2 .top_top{
  display: flex;
  justify-content: flex-end;
  vertical-align: baseline;
  width: 100%;
  border-radius: 50%;
} */
.cen-bot .item .top_top .top_img{
  margin-left: 10px;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
}
.cen-bot .item .top {
  display: flex;
  align-items: center;
}

.cen-bot .item .info {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.cen-bot .item .top_top .name{
  margin-left: 20px;
  line-height: 44px;
  text-align: center;
  font-size: 44px;
  color: #45f5ff;
}
.cen-bot .item .top .tel  {
  color: #fff;
  margin-bottom: 0;
}

  .cen-bot .item .txt  {
  font-size: 22px;
  color: #fff;
  line-height: 1.5;
  margin-top: 20px;
}
</style>
